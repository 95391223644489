import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Welcome from './Welcome';
import Login from './Login';

function Application() {
  useEffect(() => {
    // force a reload on root path, which should load the application if we have logged in
    window.location.href = '/';
  });

  return (
    <div>Application</div>
  );
}

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Welcome}/>
        <Route path="/login" Component={Login}/>
        <Route path="/application" Component={Application}/>
        <Route render={() => <h1>Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
